import Vue from "vue";
import App from "./App.vue";
import "./plugins/registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import Cookies from "js-cookie";
import axios from "axios";
import moment from "moment";
import VueClipboard from "vue-clipboard2";
import "vue-tel-input/dist/vue-tel-input.css";

moment.locale(Cookies.get("lang") != null ? Cookies.get("lang") : "en");

axios.defaults.headers.common = {
  Authorization: `Bearer ${Cookies.get("token")}`,
  Accept: "application/json",
  // "Content-Type": "application/json"
};
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response != null && error.response.status === 401) {
      console.log(error.response);
      store.commit("saveInfo", "");
    }
    // return Promise.reject(error);
    return error;
  }
);

Vue.prototype.moment = moment;
Vue.prototype.$http = axios;

Array.prototype.diff = function (a) {
  return this.filter(function (i) {
    return a.indexOf(i) < 0;
  });
};
Vue.filter("moment", function (value) {
  if (!value) return "";
  return moment.utc(value, "YYYY-MM-DD hh:mm:ss").fromNow();
});

Vue.filter("time", function (value) {
  if (!value) return "";

  let UTC = moment.utc(value, "YYYY-MM-DD hh:mm:ss").format();
  return moment.utc(UTC).local().format("h:mm A");
});

Vue.filter("date", function (val) {
  return val ? moment(val, "YYYY-MM-DD", "en").format("YYYY-MM-DD") : "";
});

Vue.filter("toCurrency", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});
Vue.filter("toEur", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  });
  return formatter.format(value);
});
VueClipboard.config.autoSetContainer = true; // add this line

Vue.use(VueClipboard);

NProgress.configure({
  template: `<div class="bar" role="bar" style="background:#DCBEFF;"><div class="peg"></div></div>
  <div class="spinner" role="spinner"><div class="spinner-icon"></div></div>`,
  showSpinner: false,
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});
router.afterEach(() => {
  NProgress.done();
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
