<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="600.513"
    height="400.311"
    viewBox="0 0 723.513 598.311"
    :style="$vuetify.rtl ? 'transform: scale(-1,1)' : ''"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        y1="1"
        x2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="gray" stop-opacity="0.251" />
        <stop offset="0.535" stop-color="gray" stop-opacity="0.122" />
        <stop offset="1" stop-color="gray" stop-opacity="0.102" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="0.5"
        y1="1"
        x2="0.5"
        y2="0"
        xlink:href="#linear-gradient"
      />
    </defs>
    <g id="undraw_Hello_qnas" transform="translate(0 0)">
      <path
        id="Path_1"
        data-name="Path 1"
        d="M263.006,657.192c0,36.175-21.506,48.808-48.033,48.808q-.926,0-1.843-.023c-1.229-.027-2.447-.08-3.649-.167-23.943-1.693-42.545-14.976-42.545-48.617,0-34.813,44.481-78.744,47.836-82.005l.007,0c.127-.127.194-.19.194-.19S263.006,621.014,263.006,657.192Z"
        transform="translate(-153.236 -175.887)"
        fill="#3f3d56"
      />
      <path
        id="Path_2"
        data-name="Path 2"
        d="M219.98,700.466l17.57-24.548-17.614,27.242-.047,2.818c-1.229-.027-2.447-.08-3.649-.167l1.893-36.192-.013-.28.033-.053.177-3.419-17.657-27.312,17.71,24.748.043.724,1.432-27.345-15.116-28.22,15.3,23.416,1.489-56.69.007-.194v.19l-.247,44.7,15.046-17.724-15.11,21.573-.4,24.484,14.051-23.5-14.111,27.1-.22,13.611,20.4-32.707-20.475,37.457Z"
        transform="translate(-159.995 -175.887)"
        fill="#f2f2f2"
      />
      <g
        id="Group_1"
        data-name="Group 1"
        transform="translate(623.335 219.032)"
        opacity="0.7"
      >
        <path
          id="Path_3"
          data-name="Path 3"
          d="M972.874,358.514V349.8H929.312V390.1a8.494,8.494,0,0,0,8.494,8.494h26.573a8.495,8.495,0,0,0,8.495-8.494v-8.93a11.326,11.326,0,0,0,0-22.652Zm0,18.3V362.871a6.97,6.97,0,0,1,0,13.94Z"
          transform="translate(-929.312 -349.802)"
          fill="url(#linear-gradient)"
        />
      </g>
      <path
        id="Path_4"
        data-name="Path 4"
        d="M979.444,362.4a10.341,10.341,0,1,0,10.341,10.341A10.341,10.341,0,0,0,979.444,362.4Zm0,16.7a6.364,6.364,0,1,1,6.364-6.364,6.364,6.364,0,0,1-6.364,6.364Z"
        transform="translate(-313.949 -133.295)"
        fill="#672ea7"
      />
      <path
        id="Path_5"
        data-name="Path 5"
        d="M979.444,362.4a10.341,10.341,0,1,0,10.341,10.341A10.341,10.341,0,0,0,979.444,362.4Zm0,16.7a6.364,6.364,0,1,1,6.364-6.364,6.364,6.364,0,0,1-6.364,6.364Z"
        transform="translate(-311.113 -133.295)"
        fill="#672ea7"
        opacity="0.1"
      />
      <path
        id="Path_6"
        data-name="Path 6"
        d="M782.493,276.563h39.773v36.75a7.8,7.8,0,0,1-7.8,7.8H790.29a7.8,7.8,0,0,1-7.8-7.8Z"
        transform="translate(-156.772 -55.409)"
        fill="#672ea7"
      />
      <path
        id="Path_7"
        data-name="Path 7"
        d="M971.139,352.455v36.79A7.756,7.756,0,0,1,963.383,397h3.977a7.756,7.756,0,0,0,7.756-7.756v-36.79Z"
        transform="translate(-312.803 -131.301)"
        opacity="0.1"
      />
      <path
        id="Path_8"
        data-name="Path 8"
        d="M941.635,315.118a2.322,2.322,0,0,0-1.757.892c-.522.946.424,2.012,1.286,2.662a17.113,17.113,0,0,1,4.357,4.056c1.083,1.676,1.424,4.014.239,5.62-1.546,2.1-5.236,2.548-5.593,5.129-.229,1.657,1.2,3.071,2.655,3.9,3.583,2.052,8.2,2.6,10.8,5.811,1.074-2.207,3.475-3.484,5.868-4.028s4.88-.5,7.307-.874a2.527,2.527,0,0,0,2.016-1.069c.678-1.377-1.255-3.028-.588-4.41.508-1.052,2-.97,3.089-1.392,1.774-.686,2.505-2.969,2-4.8a9.043,9.043,0,0,0-3.421-4.443c-4.03-3.167-8.877-5.03-13.779-6.369-2.28-.623-4.956-1.6-7.324-1.695C946.5,314.017,943.9,314.731,941.635,315.118Z"
        transform="translate(-308.064 -123.617)"
        fill="#eee"
      />
      <line
        id="Line_1"
        data-name="Line 1"
        x1="83.164"
        y2="259.087"
        transform="translate(271.249 275.093)"
        fill="none"
        stroke="#e0e0e0"
        stroke-miterlimit="10"
        stroke-width="8"
      />
      <g
        id="Group_2"
        data-name="Group 2"
        transform="translate(153.032 0)"
        opacity="0.7"
      >
        <path
          id="Path_9"
          data-name="Path 9"
          d="M606.992,639.252c-10.23.593-19.343,9.163-19.343,9.163l-4.407-10.931-.36.117c2.153-9.424,45.953-203.686,2.107-220.434-33.692-12.869-69.429-28.794-97.428-28.2V343.079h39.254v-1.636h31.076c5.957-4.255-2.7-7.181-12.373-9.01a8.834,8.834,0,0,0,1.037-1.463l49.772-89.3s11.449-18.809,4.089-27.8-17.991,22.9-17.991,22.9l-40.89,58.881s-41.269-43.014-67.522-64.4a45.953,45.953,0,0,0-9.773-7.386c-4.792-3.242-8.42-4.922-10.209-4.263-.018.007-.033.018-.051.025a45.808,45.808,0,0,0-12.216-1.661h-3.271v-6.71A52.464,52.464,0,0,0,470.9,180.54a58.93,58.93,0,0,0,4.67-51.312,3.4,3.4,0,0,0,1.377-.477c1.481-.953,1.628-3.039,1.485-4.795a36.428,36.428,0,0,0-18.49-28.482c-4.143-2.266-8.889-3.827-11.944-7.428-2.625-3.094-3.729-7.422-6.905-9.946-3.774-3-9.226-2.484-13.858-1.145a50.426,50.426,0,0,0-14.464,6.825c-5.01,3.431-9.531,7.839-15.3,9.738-4.1,1.349-8.542,1.323-12.666,2.59a21.274,21.274,0,0,0-13.687,13.349,27.788,27.788,0,0,0-1.171,10.434,58.551,58.551,0,0,0-5.874,13.364c-.308.167-.619.33-.924.5l.9-.4A58.868,58.868,0,0,0,380.633,193.4a52.419,52.419,0,0,0,25.149,17.848v8.118a61.839,61.839,0,0,0-20.071,8.22l-.009-.011s-.522.323-1.415.924l-.268.181c-6.051,4.107-26.329,19.114-25.644,35.331q-.04.935.011,1.876a62.353,62.353,0,0,0-1.67,14.3V397.871a57.8,57.8,0,0,0,12.4,35.845c-17.066,3.825-27.942,9.61-27.942,16.084a5.237,5.237,0,0,1,0,1.636c0,11.517,34.417,20.854,76.872,20.854,5.133,0,10.147-.138,15-.4l47.971,38.834L404.147,634.212s-25.351,19.627-5.725,26.168,59.7,11.449,59.7,11.449,6.542-11.449-2.453-16.356-21.262-2.453-21.262-2.453l1.636-9.813s133.3-114.49,105.495-153.744c-5.319-7.51-10.639-15.258-16-22.886l20.984,1.912,4.775,179.727.6-.195c-1.2,3.531-9.677,30.179,9.115,25.669,20.117-4.827,56.721-21.856,56.721-21.856S617.222,638.658,606.992,639.252ZM526.815,329.995l-39.254-11.4V285.016l42.664,45.29C528.128,330.1,526.815,329.995,526.815,329.995Z"
          transform="translate(-341.177 -75.892)"
          fill="url(#linear-gradient)"
        />
      </g>
      <circle
        id="Ellipse_1"
        data-name="Ellipse 1"
        cx="57.575"
        cy="57.575"
        r="57.575"
        transform="translate(176.09 21.603)"
      />
      <path
        id="Path_10"
        data-name="Path 10"
        d="M515.386,323.845l43.939,46.643a8.584,8.584,0,0,0,13.747-1.707l48.669-87.317-13.594-4.8-39.982,57.575S493.1,256.006,482.6,259.874,515.386,323.845,515.386,323.845Z"
        transform="translate(-216.15 -112.725)"
        fill="#672ea7"
      />
      <line
        id="Line_2"
        data-name="Line 2"
        x1="64.772"
        y2="167.927"
        transform="translate(150.501 372.65)"
        fill="none"
        stroke="#bdbdbd"
        stroke-miterlimit="10"
        stroke-width="5"
      />
      <line
        id="Line_3"
        data-name="Line 3"
        x2="64.772"
        y2="167.927"
        transform="translate(247.259 372.65)"
        fill="none"
        stroke="#bdbdbd"
        stroke-miterlimit="10"
        stroke-width="5"
      />
      <ellipse
        id="Ellipse_2"
        data-name="Ellipse 2"
        cx="75.167"
        cy="20.391"
        rx="75.167"
        ry="20.391"
        transform="translate(156.099 353.458)"
        fill="#b84733"
      />
      <ellipse
        id="Ellipse_3"
        data-name="Ellipse 3"
        cx="75.167"
        cy="20.391"
        rx="75.167"
        ry="20.391"
        transform="translate(156.099 351.859)"
        fill="#f55f44"
      />
      <line
        id="Line_4"
        data-name="Line 4"
        x2="70.921"
        y2="21.854"
        transform="translate(199.637 409.544)"
        fill="none"
        stroke="#bdbdbd"
        stroke-miterlimit="10"
        stroke-width="5"
      />
      <line
        id="Line_5"
        data-name="Line 5"
        x1="70.921"
        y2="21.854"
        transform="translate(191.975 409.544)"
        fill="none"
        stroke="#bdbdbd"
        stroke-miterlimit="10"
        stroke-width="5"
      />
      <path
        id="Path_11"
        data-name="Path 11"
        d="M275.058,182.016h22.314a44.78,44.78,0,0,1,44.78,44.78V345.145H214.209V242.865a60.849,60.849,0,0,1,60.849-60.849Z"
        transform="translate(-42.917 -36.467)"
        fill="#672ea7"
      />
      <path
        id="Path_12"
        data-name="Path 12"
        d="M214.209,386.016H342.153v69.57H270.984a56.775,56.775,0,0,1-56.775-56.775V386.016Z"
        transform="translate(-42.917 -77.338)"
        fill="#321059"
      />
      <path
        id="Path_13"
        data-name="Path 13"
        d="M366.141,308.676l78.237,74.336H530.74v-11.2l-74.367-21.591-63.615-78.558S363.871,289.515,366.141,308.676Z"
        transform="translate(-193.121 -115.116)"
        opacity="0.05"
      />
      <path
        id="Path_14"
        data-name="Path 14"
        d="M366.141,306.676l78.237,74.336H530.74v-11.2l-74.367-21.591-63.615-78.558S363.871,287.515,366.141,306.676Z"
        transform="translate(-193.121 -114.715)"
        fill="#672ea7"
      />
      <path
        id="Path_15"
        data-name="Path 15"
        d="M572.012,394.908s41.582,3.2,30.387,11.2H572.012Z"
        transform="translate(-234.392 -139.807)"
        fill="#fda57d"
      />
      <path
        id="Path_16"
        data-name="Path 16"
        d="M640.012,274.906s10.4-31.186,17.592-22.39-4,27.188-4,27.188Z"
        transform="translate(-248.016 -110.966)"
        fill="#fda57d"
      />
      <path
        id="Path_17"
        data-name="Path 17"
        d="M214.209,386.016v3.732l127.944.266v-4Z"
        transform="translate(-42.917 -77.338)"
        opacity="0.05"
      />
      <path
        id="Path_18"
        data-name="Path 18"
        d="M486.017,536.933l86.064,7.843,4.669,175.741,30.811-10.031s46.081-199.111,2.14-215.895-91.439-38.884-119.8-20.873S486.017,536.933,486.017,536.933Z"
        transform="translate(-215.198 -154.251)"
        fill="#321059"
      />
      <path
        id="Path_19"
        data-name="Path 19"
        d="M495.012,486.908s47.179,23.99,53.577,51.178"
        transform="translate(-218.966 -158.239)"
        opacity="0.1"
      />
      <path
        id="Path_20"
        data-name="Path 20"
        d="M430.009,535.284l67.17,54.376L422.012,710.407l31.186,8.8S583.541,607.252,556.353,568.869s-54.376-83.164-87.962-83.963S430.009,535.284,430.009,535.284Z"
        transform="translate(-204.34 -157.836)"
        fill="#321059"
      />
      <path
        id="Path_21"
        data-name="Path 21"
        d="M414.209,251.016,431,279.8l4-8Z"
        transform="translate(-82.986 -50.291)"
        opacity="0.05"
      />
      <path
        id="Path_22"
        data-name="Path 22"
        d="M418.784,766.908s-24.789,19.192-5.6,25.589,58.375,11.2,58.375,11.2,6.4-11.2-2.4-15.993-20.791-2.4-20.791-2.4l1.6-9.6Z"
        transform="translate(-201.112 -214.337)"
        fill="#333"
      />
      <path
        id="Path_23"
        data-name="Path 23"
        d="M602.082,780.836s-10.889,30.2,8.782,25.477,55.463-21.371,55.463-21.371-.491-12.885-10.494-12.3-18.914,8.96-18.914,8.96l-4.309-10.689Z"
        transform="translate(-239.815 -215.138)"
        fill="#333"
      />
      <rect
        id="Rectangle_1"
        data-name="Rectangle 1"
        width="31.986"
        height="47.979"
        rx="15.993"
        transform="translate(219.271 117.561)"
        fill="#fda57d"
      />
      <path
        id="Path_24"
        data-name="Path 24"
        d="M440,224.908A15.993,15.993,0,0,0,424.012,240.9v5.434a51.182,51.182,0,0,0,31.986,0V240.9a15.993,15.993,0,0,0-15.993-15.993Z"
        transform="translate(-204.741 -105.748)"
        opacity="0.05"
      />
      <circle
        id="Ellipse_4"
        data-name="Ellipse 4"
        cx="51.178"
        cy="51.178"
        r="51.178"
        transform="translate(184.087 39.196)"
        fill="#fda57d"
      />
      <path
        id="Path_25"
        data-name="Path 25"
        d="M379.211,171.76s12.794,3.3,19.991-11.244S412,138.042,441.583,146.635s35.539,9.641,36.961,1.516c.972-5.552-9.549-19.039-16.309-27.024a18.932,18.932,0,0,0-14.432-6.219H414.4L394.4,124.162l-15.193,13.22-3.2,17.846Z"
        transform="translate(-195.124 -83.709)"
        opacity="0.05"
      />
      <path
        id="Path_26"
        data-name="Path 26"
        d="M379.211,169.76s12.794,3.3,19.991-11.244S412,136.042,441.583,144.635s35.539,9.641,36.961,1.516c.972-5.552-9.549-19.039-16.309-27.024a18.932,18.932,0,0,0-14.432-6.219H414.4L394.4,122.162l-15.193,13.22-3.2,17.846Z"
        transform="translate(-195.124 -83.308)"
      />
      <ellipse
        id="Ellipse_5"
        data-name="Ellipse 5"
        cx="5.198"
        cy="10.395"
        rx="5.198"
        ry="10.395"
        transform="translate(184.087 75.98)"
        fill="#fda57d"
      />
      <path
        id="Path_27"
        data-name="Path 27"
        d="M379.866,137.181c-1.186-6.694-2.351-13.723-.192-20.17a20.8,20.8,0,0,1,13.384-13.053c4.032-1.24,8.378-1.213,12.385-2.533,5.639-1.857,10.06-6.167,14.959-9.522a49.3,49.3,0,0,1,14.143-6.674c4.529-1.309,9.86-1.813,13.55,1.12,3.106,2.469,4.185,6.7,6.752,9.726,2.988,3.521,7.628,5.047,11.679,7.263a35.62,35.62,0,0,1,18.08,27.851c.14,1.717,0,3.756-1.452,4.688a5.184,5.184,0,0,1-2.917.532c-20.454-.292-40.932-.586-61.3-2.464-6.332-.584-12.677-1.321-19.032-1.08a62.257,62.257,0,0,0-28.023,7.907"
        transform="translate(-194.296 -77.555)"
      />
      <rect
        id="Rectangle_2"
        data-name="Rectangle 2"
        width="123.214"
        height="52.432"
        rx="26.216"
        transform="translate(0 515.988)"
        fill="#672ea7"
      />
      <path
        id="Path_28"
        data-name="Path 28"
        d="M725.389,486.474a1.2,1.2,0,1,0-1.158-1.2A1.2,1.2,0,0,0,725.389,486.474Z"
        transform="translate(-264.889 -157.671)"
        fill="url(#linear-gradient-3)"
      />
      <line
        id="Line_6"
        data-name="Line 6"
        x2="83.164"
        y2="295.944"
        transform="translate(636.499 275.093)"
        fill="none"
        stroke="#e0e0e0"
        stroke-miterlimit="10"
        stroke-width="8"
      />
      <rect
        id="Rectangle_3"
        data-name="Rectangle 3"
        width="449.089"
        height="17.5"
        transform="translate(270.911 266.426)"
        fill="#e0e0e0"
      />
      <rect
        id="Rectangle_4"
        data-name="Rectangle 4"
        width="73.568"
        height="10.395"
        rx="5.198"
        transform="translate(381.601 262.298)"
        fill="#535461"
      />
      <g
        id="Group_3"
        data-name="Group 3"
        transform="translate(426.381 146.398)"
        opacity="0.7"
      >
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="182.847"
          height="126.426"
          rx="28.22"
          fill="url(#linear-gradient)"
        />
      </g>
      <rect
        id="Rectangle_6"
        data-name="Rectangle 6"
        width="173.212"
        height="124.093"
        rx="26.835"
        transform="translate(429.641 148.73)"
        fill="#3e3f49"
      />
      <rect
        id="Rectangle_7"
        data-name="Rectangle 7"
        width="173.212"
        height="124.093"
        rx="26.835"
        transform="translate(432.04 148.73)"
        fill="#535461"
      />
      <circle
        id="Ellipse_6"
        data-name="Ellipse 6"
        cx="5.598"
        cy="5.598"
        r="5.598"
        transform="translate(521.14 204.324)"
        fill="#e0e0e0"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "LoginSVG",
};
</script>

<style scoped></style>
