<template>
  <v-app>
    <app-bar />
    <v-main>
      <router-view />
    </v-main>
    <v-snackbar v-model="$store.state.snackbar">{{
      $t($store.state.snackTxt)
    }}</v-snackbar>
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar";
export default {
  name: "App",

  components: {
    AppBar,
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
@import "./styles/index.scss";
</style>
