<template>
  <v-container
    class="fill-height sign_in"
    fluid
    @keypress.enter.prevent="login()"
  >
    <v-row justify="center" class="align-center">
      <v-col cols="6" class="hidden-sm-and-down">
        <v-row>
          <v-col cols="12">
            <v-card
              color="rgba(255,255,255,0.6)"
              height="100"
              max-width="500"
              class="text-center rounded-lg"
              flat
            >
              <h1 style="line-height: 100px; color: #321059" class="no_select">
                {{ $t("data_extraction") }}
              </h1>
            </v-card>
          </v-col>
          <v-col cols="12">
            <login-s-v-g width="400" />
          </v-col>
        </v-row>
      </v-col>

      <v-col md="5" sm="8">
        <v-card color="rgba(255,255,255,0.6)" class="rounded-lg" flat>
          <v-card-title class="no_select pt-5 pe-2">
            <logo-svg class="mx-auto" />
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form_sign_in"
              lazy-validation
              class="mt-8"
              @submit="login()"
            >
              <v-text-field
                v-model="initData.form.Login1$UserName"
                :label="$t('username')"
                :rules="rules"
                required
                name="username"
                type="username"
                class="rounded-lg"
                height="60"
                solo
              ></v-text-field>

              <v-text-field
                v-model="initData.form.Login1$Password"
                :label="$t('password')"
                name="password"
                :rules="rules"
                class="rounded-lg"
                height="60"
                solo
                rounded
                required
                :append-icon="showPass ? 'visibility' : 'visibility_off'"
                :type="showPass ? 'text' : 'password'"
                @click:append="showPass = !showPass"
              ></v-text-field>

              <v-text-field
                v-model="initData.form.Login1$txt2FaCode"
                :label="$t('code2f')"
                name="code2f"
                type="code2f"
                :rules="rules"
                class="rounded-lg"
                height="60"
                solo
                rounded
                required
                @keypress="numberOnly($event)"
              ></v-text-field>

              <v-row>
                <v-col cols="6">
                  <v-card
                    color="rgb(214,209,189)"
                    :class="$vuetify.rtl ? 'rounded-r-lg' : 'rounded-l-lg'"
                  >
                    <v-img height="70" contain :src="initData.image"></v-img>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="
                      initData.form.Login1$ImageVerificationDealer$txtContent
                    "
                    :label="$t('image_code')"
                    name="imageCode"
                    type="imageCode"
                    :rules="rules"
                    solo
                    required
                    height="70"
                    :class="$vuetify.rtl ? 'rounded-l-lg' : 'rounded-r-lg'"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions class="justify-end">
            <div class="mx-auto">
              <v-alert
                :value="alert"
                dense
                color="red darken-2"
                type="error"
                dismissible
                tile
                transition="slide-y-reverse-transition"
              >
                {{ $t(message) }}
              </v-alert>
            </div>
            <v-btn
              color="#321059"
              class="font-weight-bold white--text ma-4"
              x-large
              min-width="170"
              elevation="0"
              @click="login()"
              :loading="loading"
              >{{ $t("sign_in") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { sign_in } from "@/api/URLs";
import LoginSVG from "@/components/widgets/LoginSVG";
import LogoSvg from "@/components/widgets/LogoSvg";
import axios from "axios";

export default {
  name: "SignIn",
  components: { LogoSvg, LoginSVG },
  data() {
    return {
      showPass: false,
      initData: { form: {} },
      loading: false,
      alert: true,
      message: "",
      emailRules: [
        (v) => !!v || this.$t("email_required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("email_valid"),
      ],
      rules: [(v) => !!v || this.$t("this_required")],
    };
  },
  created() {
    this.init();

    /*    let token =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiY2NjNTBhY2JlMTA3YzQ5YWJmNTIxZjEzODJlNjhjZDgzODliNzdmODY2ZjhkYTg4ZjE2ZGM5ZDY3OGZmNzFjNmJmYjA2Y2I3OTRiNGRmYzUiLCJpYXQiOjE2MzUyNzkzOTcuNDcxNTA2LCJuYmYiOjE2MzUyNzkzOTcuNDcxNTEyLCJleHAiOjE2NjY4MTUzOTcuMzE4NzUxLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.y8spfZYgjBabrjyBbHE_Ehykqxp9CNxBuWX66u8tTNkd_VIgje5FHQsqL-SraIhl56J-mjg5UVW_AEjFiQ3ChMlFR4AGJm8ctS-_d9SneHjkf3b3AcpdReVsrVlFO6iLdCdl8APXMQm5znW7ParwuSxL6z7_CqFvfXEsHhg97FRyJGi4PKH475Htikp4K5fvKHsDm_6jVMR8OC9SSaZ9_SoiwGCK_UQjFKlLVGvQaaUuYwsCPIu1EaIaFWfyNKwkfuQF-0iJd0j5NjESd80XQBH3XNSfgVPPqepawRAw-JGbzR2hgTiMr9sNMEGN5cdqX4AZIgeMde8sMl2ZedFyrMXbwRfGjDhAF7zVLuxTH5ExoEj8118MyvJcBIJFJW_TmSLd4x-XHw4fAKesqs1gJwcVwHkNlUxwYOlY8bsm5pl3BfNNBn93pScapMnjiD03NLaouKTjoD7-zXhkWvjv1tAqmNCC68d-QtdtNAlsa1sLEcMANRKdzi1sPsGGAMNBTx25DppJQyaadSEl2HNpBzbGq18fhhZeI3vcKOMV2clqwQuuF0IeWrsaX0Ldav0p138JyQtEiCQWGx8wxsrw9bfNos-Ccx8GCu1HXMWSimntDI_KS81uzrJNw_Dzyy3GggMahxlS4TsjtuLyZ3bhQ2sRclmyARFn0MAUQTqxFbI";
    this.$store.commit("saveInfo", token);
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    this.$router.replace({ name: "dashboard" });*/
  },
  methods: {
    numberOnly($event) {
      if ($event.keyCode < 48 || $event.keyCode > 57) $event.preventDefault();
    },
    removeSpace($event) {
      if ($event.keyCode === 32) $event.preventDefault();
    },
    login() {
      this.alert = false;
      if (this.$refs.form_sign_in.validate()) {
        this.loading = true;
        let self = this;
        this.$http({
          method: "post",
          url: sign_in,
          data: self.initData,
        }).then(function (response) {
          console.log(response);
          if (response.data.success) {
            self.$store.commit("saveInfo", response.data.token);
            axios.defaults.headers.common = {
              Authorization: `Bearer ${response.data.token}`,
            };
            self.$router.replace({ name: "dashboard" });
          } else {
            if (response.data.data != null) {
              response.data.data.form.Login1$Password =
                self.initData.form.Login1$Password;
              self.initData = response.data.data;
              self.$refs.form_sign_in.resetValidation();
            }
            self.message = response.data.errors[0];
            self.alert = true;
            self.loading = false;
          }
        });
      }
    },
    init() {
      this.alert = false;
      this.loading = true;
      let self = this;
      this.$http({
        method: "get",
        url: sign_in,
      }).then(function (response) {
        self.initData = response.data.data;
        self.$refs.form_sign_in.resetValidation();
        self.loading = false;
      });
    },
  },
};
</script>
