<template>
  <v-app-bar
    elevate-on-scroll
    app
    color="primary"
    class="text-center"
    height="50"
  >
    <logo-svg :is-colored="false" width="80" />

    <v-spacer></v-spacer>

    <v-toolbar-title
      class="white--text"
      v-if="$route.name !== 'sign_in' && $route.name !== 'my_subscribe'"
      v-text="
        $route.name === 'new_email' && $route.params.id != null
          ? $t('edit_email')
          : $t(title)
      "
    />

    <v-spacer></v-spacer>
    <v-btn
      dark
      icon
      class="me-4"
      @click="dialog = true"
      v-if="$route.name !== 'sign_in' && $route.name !== 'my_subscribe'"
    >
      <v-icon>exit_to_app</v-icon>
    </v-btn>
    <v-btn dark icon class="me-4">
      <app-screenfull />
    </v-btn>
    <lang color="white" />

    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="headline">{{ $t("sign_out") }}</v-card-title>
        <v-checkbox
          v-model="logOutAll"
          :label="$t('sign_out_all')"
          class="ms-6"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
            v-text="$t('cancel')"
            :loading="loading"
          />
          <v-btn
            color="primary"
            :loading="loading"
            dark
            @click="signOut()"
            v-text="$t('yes')"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import AppScreenfull from "@/components/widgets/AppScreenfull";
import lang from "@/components/widgets/lang";
import LogoSvg from "@/components/widgets/LogoSvg";
import { logout } from "@/api/URLs";
export default {
  name: "AppBar",
  components: {
    LogoSvg,
    AppScreenfull,
    lang,
  },
  data() {
    return {
      title: this.$router.currentRoute.name,
      logOutAll: false,
      dialog: false,
      loading: false,
    };
  },
  watch: {
    $route(val) {
      this.title = val.name;
    },
  },
  methods: {
    signOut() {
      this.loading = true;
      let self = this;
      this.$http({
        method: "post",
        url: logout,
        data: { log_out_all_devices: self.logOutAll },
      }).then(function (response) {
        self.loading = false;
        if (response.data.success) {
          self.dialog = false;
          self.$store.commit("saveInfo", "");
        }
      });
    },
  },
};
</script>

<style scoped></style>
