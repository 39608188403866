import Vue from "vue";
import VueRouter from "vue-router";
import SignIn from "../views/SignIn.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "sign_in",
    component: SignIn,
    beforeEnter: (to, from, next) => {
      if (store.state.token !== "") {
        next("dashboard");
      } else {
        next();
      }
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/Dashboard.vue"),
    beforeEnter: (to, from, next) => {
      if (store.state.token === "") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/send/:type",
    name: "send_page",
    component: () => import("../views/SendPage.vue"),
    beforeEnter: (to, from, next) => {
      if (store.state.token === "") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/emails",
    name: "emails",
    component: () => import("../views/Emails.vue"),
    beforeEnter: (to, from, next) => {
      if (store.state.token === "") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/email/:id?",
    name: "new_email",
    component: () => import("../views/NewEmail.vue"),
    beforeEnter: (to, from, next) => {
      if (store.state.token === "") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/messages",
    name: "messages",
    component: () => import("../views/Messages.vue"),
    beforeEnter: (to, from, next) => {
      if (store.state.token === "") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/pos",
    name: "pos",
    component: () => import("../views/Pos.vue"),
    beforeEnter: (to, from, next) => {
      if (store.state.token === "") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/MySubscribe",
    name: "my_subscribe",
    component: () => import("../views/MySubscribe.vue"),
  },

  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
