/* eslint-disable */
//const baseURI = "http://127.0.0.1:8000/";
const baseURI = "https://api.noorshilan.com/";
//const baseURI = "https://beinapi.profaws.com/";

// ************  Users  *************
export const sign_in = baseURI + "sign_in";
export const logout = baseURI + "logout";

export const init = baseURI + "init";
export const refresh = baseURI + "invoice/refresh";
export const incompleteCount = baseURI + "invoice/incomplete";
export const complete = baseURI + "invoice/complete";
export const filter = baseURI + "invoice/filter";

export const email = baseURI + "email";
export const message = baseURI + "message";
export const messageSpent = baseURI + "message/spent";

export const sendEmails = baseURI + "send_emails";
export const sendMessages = baseURI + "send_messages";

export const sendTestMessage = baseURI + "send_test_message";
export const sendTestEmail = baseURI + "send_test_email";

export const upload = baseURI + "upload";

export const mySub = baseURI + "my-sub";

export const pos = baseURI + "pos";