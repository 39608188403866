import Vue from "vue";
import Vuex from "vuex";
import Cookies from "js-cookie";
import axios from "axios";
import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loginDialog: false,
    snackbar: false,
    snackTxt: "",
    token: Cookies.get("token") != null ? Cookies.get("token") : "",
    pictureLibrary: false,
    selected: [],
  },
  mutations: {
    saveInfo(state, status) {
      Cookies.set("token", status, {
        expires: 365,
      });
      state.token = status;
      if (status === "") {
        axios.defaults.headers.common = null;
        router.replace({ name: "sign_in" }).catch(() => {});
      }
    },
    showToast(state, status) {
      state.snackTxt = status;
      state.snackbar = true;
    },
  },
  actions: {},
  modules: {},
});
