<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
          width="120"
          tile
          large
          :color="color"
          style="border-radius: 5px;"
          icon
          v-on="on"
        >
          <v-icon left>translate</v-icon>
          <span
            style="font-weight: normal;"
            v-text="$i18n.locale === 'ar' ? 'عربي' : 'English'"
          />
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(lang, index) in langList"
          :key="index"
          @click="changeLang(lang.name)"
        >
          <v-list-item-title v-text="lang.title" />
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "lang",
  props: ["color"],
  data: () => ({
    langList: [
      { title: "English", name: "en" },
      { title: "عربي", name: "ar", active: false }
    ]
  }),
  methods: {
    changeLang: function(name) {
      this.$root.$i18n.locale = name;
      this.moment.locale(name);
      name === "ar" ? (this.$vuetify.rtl = true) : (this.$vuetify.rtl = false);
      Cookies.set("lang", name, { expires: 365 });
    }
  }
};
</script>

<style scoped></style>
