import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import Cookies from "js-cookie";
import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#421278",
      },
    },
  },
  icons: {
    iconfont: "md",
  },
  rtl: Cookies.get("lang") != null ? Cookies.get("lang") === "ar" : false,
});
